import { graphql, PageProps } from "gatsby"
import Template from "@components/Collection/Collection"

export type Props = PageProps<GatsbyTypes.TemplateCollectionQuery, GatsbyTypes.TemplateCollectionQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query TemplateCollection($handle: String!) {
    collection: sanityCollection(shopify: { handle: { eq: $handle }, deleted: { ne: true }, published: { eq: true } }) {
      ...SanityCollectionFragment
    }
    template: sanityTemplateCollection {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      settingShowSubCollections
      content: _rawContent(resolveReferences: { maxDepth: 8 })
    }
  }
  fragment SanityCollectionFragment on SanityCollection {
    id
    url
    title
    isBrand
    descriptionHtml
    promotionalCards: _rawPromotionalCards(resolveReferences: { maxDepth: 4 })
    image
    shopify {
      id
      handle
    }
    metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    parentCollection: _rawParentCollection(resolveReferences: { maxDepth: 6 })
    _type
    headerFilter
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
