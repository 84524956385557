import { memo } from "react"
import { Grid, GridItem, Heading, Text } from "@chakra-ui/react"
import { CustomButton } from "@components/Button"

type CollectionPromotionalCardsProps = {
  cards?: GatsbyTypes.SanityCollection["promotionalCards"]
}

const CollectionPromotionalCards: React.FC<CollectionPromotionalCardsProps> = ({ cards }) => {
  const hasCards = !!cards?.length
  if (!hasCards) return null

  return (
    <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]} gap={3} mb={14}>
      {cards?.map(card => {
        return (
          <GridItem
            colSpan={1}
            minH={[32, 32, 48]}
            key={card?._key}
            bgColor="brand.sand"
            bgImage={card?.image?.asset?.url}
            bgSize="cover"
            bgPosition="center"
            py={[2, 2, 8]}
            px={[5, 5, 8]}
          >
            {card?.title && (
              <Heading size="h4" as="p" pb={1}>
                {card?.title}
              </Heading>
            )}
            {card?.subtitle && (
              <Text pb={3} fontSize={["sm", "md"]}>
                {card?.subtitle}
              </Text>
            )}
            <CustomButton button={card?.button} width={[28, 28, 34]} fontSize="sm" />
          </GridItem>
        )
      })}
    </Grid>
  )
}

const MemoCollectionPromotionalCards = memo(CollectionPromotionalCards)
export { MemoCollectionPromotionalCards as CollectionPromotionalCards }
