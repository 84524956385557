import { memo, useCallback } from "react"
import { Tag, TagLabel, TagCloseButton, HStack } from "@chakra-ui/react"

import type { MouseEvent } from "react"

type SelectableTagProps = {
  checked?: boolean
  handleClick?: (event: MouseEvent<HTMLSpanElement>) => void
  label?: string
  value?: string
  variant?: "brand"
  [rest: string]: any
}

type SelectableTagsProps = {
  tags?: Array<string>
  resetLabel?: string
  selectedTag?: string
  handleClick?: (event: MouseEvent<HTMLSpanElement>) => void
  showReset?: boolean
  upperCaseTags?: boolean
  variant?: "brand"
  [rest: string]: any
}

const DEFAULT_RESET_LABEL = "Show All"

const SelectableTag: React.FC<SelectableTagProps> = ({ checked = false, handleClick, label, value, variant, ...props }) => {
  const tagVariant = `${variant || "subcategory"}${checked ? "-active" : ""}`

  const handleTagCloseClick = useCallback(
    (e: React.SyntheticEvent) => {
      e?.stopPropagation()
      handleClick?.(DEFAULT_RESET_LABEL)
    },
    [handleClick]
  )

  return (
    <Tag variant={tagVariant} onClick={handleClick} cursor="pointer" height="auto" data-value={value || label} {...props}>
      <TagLabel pointerEvents="none" lineHeight="unset">
        {label || value}
      </TagLabel>
      {checked && <TagCloseButton onClick={handleTagCloseClick} />}
    </Tag>
  )
}

const SelectableTags: React.FC<SelectableTagsProps> = ({
  tags,
  resetLabel = DEFAULT_RESET_LABEL,
  selectedTag,
  handleClick,
  showReset = true,
  upperCaseTags = false,
  variant,
  ...props
}) => {
  const noTags = !tags || !tags?.length
  if (noTags) return null

  return (
    <HStack
      spacing={0}
      columnGap={variant !== "brand" ? 2 : { base: 2, lg: 0 }}
      rowGap={variant !== "brand" ? 2 : { base: 2, lg: 0 }}
      wrap="wrap"
      justifyContent={variant !== "brand" ? null : { lg: "space-between" }}
    >
      {showReset && (
        <SelectableTag
          label={resetLabel}
          value={resetLabel}
          checked={selectedTag ? resetLabel === selectedTag : true}
          handleClick={handleClick}
          w="unset"
          px={4}
        />
      )}
      {tags?.map((label, idx) => (
        <SelectableTag
          key={idx}
          value={label}
          label={upperCaseTags ? label?.toUpperCase() : label}
          // displayLabel
          checked={label === selectedTag}
          handleClick={handleClick}
          variant={variant}
          {...props}
        />
      ))}
    </HStack>
  )
}

const MemoSelectableTag = memo(SelectableTag)
const MemoSelectableTags = memo(SelectableTags)
export { MemoSelectableTag as SelectableTag, MemoSelectableTags as SelectableTags, DEFAULT_RESET_LABEL }
