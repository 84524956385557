import { useShop } from "@app/hooks/useShop"
import { useConfigContext } from "@app/providers/config"
import { useMemo, useCallback, useEffect } from "react"
import { useAppContext } from "@app/providers/app"
import { useShopify } from "@app/hooks/useShopify"
import { useDeepDependency } from "@app/hooks/useDeepDependency"

import type { Props } from "@app/templates/collection"
import type { PageProps } from "@root/types/global"

type usePageCollectionProps = {
  collection?: PageProps<Props>["collection"]
}

const usePageCollection = ({ collection }: usePageCollectionProps) => {
  const { shop } = useShop()
  const {
    store: { searchIndex, shopifyShopDomain },
  } = useConfigContext()
  const { activeCollection, setActiveCollection } = useAppContext()
  const { collectionNormaliser } = useShopify()

  const shopifyPermanentDomain = useMemo(() => shop?.myshopifyDomain ?? shopifyShopDomain, [shop?.myshopifyDomain, shopifyShopDomain])

  const item = useMemo(() => collectionNormaliser(collection), [collection, collectionNormaliser])

  const itemRef = useDeepDependency(item)

  // ***** is the custom divider that the client uses in shopify collections
  const [headerContent, bottomContent] = item?.descriptionHtml?.split("*****") || []

  useEffect(() => {
    if (item?.id !== activeCollection?.id) setActiveCollection(item)
  }, [item, activeCollection?.id, setActiveCollection])

  const getPageCollection = useCallback(() => {
    return {
      shopifyPermanentDomain,
      searchIndex,
      item: itemRef?.current,
      headerContent,
      bottomContent,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopifyPermanentDomain, searchIndex, itemRef?.current, headerContent, bottomContent])

  return { getPageCollection }
}

export { usePageCollection }
