import { memo } from "react"
import { Box, Container, Flex, Heading } from "@chakra-ui/react"

type ListingHeaderProps = {
  title?: string
  children?: React.ReactNode
  showBorder?: boolean
  paddingTop?: any
  paddingBottom?: any
  bgColor?: any
}

const ListingHeader: React.FC<ListingHeaderProps> = ({
  title,
  children,
  showBorder = true,
  paddingTop = [14, 18],
  paddingBottom = 8,
  bgColor = ["brand.midSand", "brand.lightSand"],
}) => {
  return (
    <Box
      as="section"
      pt={paddingTop}
      pb={paddingBottom}
      bg={bgColor}
      borderBottom={showBorder ? "1px" : "none"}
      borderColor="brand.avocado"
    >
      <Container>
        <Flex justifyContent="space-between">
          <Heading as="h1" size={["h3", "h2"]} mb={2} fontSize={["4xl", "8xl"]}>
            {title}
          </Heading>
        </Flex>
        {children}
      </Container>
    </Box>
  )
}

const MemoListingHeader = memo(ListingHeader)
export { MemoListingHeader as ListingHeader }
