import { Filter, useFilterListProps, useFilters } from "@usereactify/search"
import { HStack } from "@chakra-ui/react"
import { useStringHelpers } from "@app/hooks/useStringHelpers"
import { SelectedFilters } from "@appbaseio/reactivesearch"
import { SelectableTag } from "@components/SelectableTags"
import { memo } from "react"

type SearchFilterTopCollectionProps = {
  topCollectionHandle?: string
}

type SearchFilterTopCollectionButtonsProps = ReturnType<typeof useFilterListProps>

type SearchFilterTopCollectionButtonProps = {
  checked: boolean
  name: string
  handleClick: (name: string) => void
  filter?: any
}

const SearchFilterTopCollectionButton: React.FC<SearchFilterTopCollectionButtonProps> = ({ filter, checked, name, handleClick }) => {
  const { deHandleize } = useStringHelpers()

  const getFilterTitle = (title: string) => {
    const deHandleizedTitle = deHandleize(title)
    return filter?.settingsUppercase ? deHandleizedTitle?.toUpperCase() : deHandleizedTitle
  }

  const filterTitle = getFilterTitle(name)

  return <SelectableTag label={filterTitle} value={filterTitle} checked={checked} handleClick={() => handleClick(name)} />
}

const SearchFilterTopCollectionButtons: React.FC<SearchFilterTopCollectionButtonsProps> = ({
  filter,
  options,
  handleChange,
  hasSelected,
}: any) => {
  const excludedValueOptions = options?.filter(({ key }: any) => !filter.valuesExclude.includes(key))

  return (
    <HStack wrap="wrap" gap={2} spacing={0}>
      {/* @ts-ignore */}
      <SelectedFilters
        render={({ clearValues }) => <SearchFilterTopCollectionButton checked={!hasSelected} handleClick={clearValues} name="show-all" />}
      />
      {excludedValueOptions?.map(({ key, checked }: any) => (
        <SearchFilterTopCollectionButton key={key} checked={checked} name={key} filter={filter} handleClick={handleChange} />
      ))}
    </HStack>
  )
}

const SearchFilterTopCollection: React.FC<SearchFilterTopCollectionProps> = ({ topCollectionHandle }) => {
  const filtersHook = useFilters()
  const onlyTopCollectionFilter = filtersHook.filters?.find(({ handle }: { handle: string }) => handle === topCollectionHandle)

  if (!onlyTopCollectionFilter) return <></>

  return (
    <>
      <Filter
        key={onlyTopCollectionFilter.id}
        filter={onlyTopCollectionFilter}
        renderFilterList={({ filterListProps }) => <SearchFilterTopCollectionButtons {...filterListProps} />}
      />
    </>
  )
}

const MemoSearchFilterTopCollection = memo(SearchFilterTopCollection)
export { MemoSearchFilterTopCollection as SearchFilterTopCollection }
