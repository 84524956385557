// import { useMedia } from "@app/hooks/useMedia"
import { Box } from "@chakra-ui/react"
import { SearchFilters } from "@components/Search/Filters/SearchFilters"
import { useFilters } from "@usereactify/search"
import { memo } from "react"

type CollectionSideBarFiltersDesktopProps = {
  topCollectionHandle?: string
}

const CollectionSideBarFiltersDesktop: React.FC<CollectionSideBarFiltersDesktopProps> = ({ topCollectionHandle }) => {
  const filtersHook = useFilters()

  return (
    <Box py={4} pr={4} bg="brand.lightSand" w={52} display={["none", "none", "block"]}>
      <SearchFilters filters={filtersHook.filters} topCollectionHandle={topCollectionHandle} />
    </Box>
  )
}

const MemoCollectionSideBarFiltersDesktop = memo(CollectionSideBarFiltersDesktop)
export { MemoCollectionSideBarFiltersDesktop as CollectionSideBarFiltersDesktop }
