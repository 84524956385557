import { memo } from "react"
import { Box, Text, Center, useBoolean } from "@chakra-ui/react"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { PADDING_BOTTOM, LINE_HEIGHT } from "@app/hooks/useContent"

type CollectionContentProps = {
  content?: any
}

const fontSize = "md"

const CollectionContent: React.FC<CollectionContentProps> = ({ content }) => {
  const [showAllContent, setShowAllContent] = useBoolean()
  const locales = useLocale(LOCALE_KEYS.COLLECTION)

  const showMoreContentTxt = locales?.showMoreContent || "Read More"
  const showLessContentTxt = locales?.showLessContent || "Show Less"

  // These line up with the rules in useContent
  const contentStyles = {
    "h1, h2, h3, h4, h5, h6": {
      color: "brand.avocado",
      fontWeight: "black",
      fontFamily: "heading",
    },
    p: {
      pb: PADDING_BOTTOM,
      fontSize,
      lineHeight: LINE_HEIGHT,
    },
    "*": {
      fontSize,
      lineHeight: LINE_HEIGHT,
    },
    a: {
      color: "brand.avocado",
      textDecoration: "underline",
    },
  }

  if (!content) return null

  return (
    <Center py={[16, 14]}>
      <Box w={["full", "full", "70%"]} position="relative">
        <Box
          noOfLines={showAllContent ? undefined : 5}
          sx={contentStyles}
          dangerouslySetInnerHTML={{ __html: content }}
          // noOfLines workaround for safari
          maxH={showAllContent ? "unset" : 24 * 5}
          overflow="hidden"
        />
        <Text
          as="span"
          fontWeight={700}
          position="absolute"
          bottom={-5}
          right={0}
          cursor="pointer"
          onClick={setShowAllContent.toggle}
          fontSize={["sm", "md"]}
          lineHeight={1.6}
        >
          {showAllContent ? showLessContentTxt : showMoreContentTxt}
        </Text>
      </Box>
    </Center>
  )
}

const MemoCollectionContent = memo(CollectionContent)
export { MemoCollectionContent as CollectionContent }
